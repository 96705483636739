import CurrentUserContext from "../../client/UserContextProvider";
import { getAllCardsForUser } from "../../services/cardService"
import { CardType } from "../../types/CardType";
import { useContext, useEffect, useState } from "react"
import Styles from "../../css/profile/dashboard.module.css"
import CustomAccordion from "../shared/CustomAccordion";
import { Button } from "react-bootstrap";
import { getSubmissions } from "../../services/submissionService";
import { PaymentStateEnum, SubmissionType } from "../../types/SubmissionType";
import { AccordionSummary, Typography, AccordionDetails, Accordion, Alert } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SubmissionsCustomAccordion from "../shared/SubmissionsCustomAccordion";
import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string';
export default function UserDashboard() {
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const [paidCards, setPaidCards] = useState<CardType[]>([])
    const [pendingPaymentCards, setPendingPaymentCards] = useState<CardType[]>([])
    const [userSubmissions, setUserSubmissions] = useState<SubmissionType[]>([])
    const [submissionsHeadingAccordion, setSubmissionsHeadingAccordion] = useState<boolean>(false)
    const [archivedCardsHeadingAccordion, setArchivedCardsHeadingAccordion] = useState<boolean>(false)
    const [archivedSubmissionsHeadingAccordion, setArchivedSubmissionsHeadingAccordion] = useState<boolean>(false)
    const [paidCardsHeadingAccordion, setPaidCardsHeadingAccordion] = useState<boolean>(false)
    const [pendingPaymentCardsHeadingAccordion, setPendingPaymentCardsHeadingAccordion] = useState<boolean>(false)
    const location = useLocation();
    const { code } = queryString.parse(location.search);
    useEffect(() => {
        const retrieveCardData = async () => {
            if (currentUser) {
                var cards: CardType[] = await getAllCardsForUser(currentUser?.id!)
                var submissions: SubmissionType[] = await getSubmissions(currentUser?.id!)
                console.log(cards)
                setUserSubmissions(submissions)
                setPaidCards(cards.filter(x => x.pendingPayment == false))
                setPendingPaymentCards(cards.filter(x => x.pendingPayment))
            }
        }
        retrieveCardData()
    }, [currentUser])
    const submissions = userSubmissions.filter(x => x.paymentState != PaymentStateEnum.Paid)
    const archivedSubmissions = userSubmissions.filter(x => x.paymentState == PaymentStateEnum.Paid)
    const removePaidCardFromList = (id: number) => {
        setPaidCards(paidCards.filter(x => x.id != id))
    }
    const removePendingPaymentCardFromList = (id: number) => {
        setPendingPaymentCards(pendingPaymentCards.filter(x => x.id != id))
    }

    const returnHeadingAccordion = (isExpanded: boolean, setIsExpanded: (value: boolean) => void, heading: string, count: number, items: CardType[], removeMethod: (id: number) => void) => {
        if (count > 0) {
        return <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)} sx={{ backgroundColor: "rgba(70, 75, 86, 0.60)", boxShadow: "none" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <h3 style={{ color: "white", margin: "1%" }}>{heading} <small>({count})</small></h3>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {items.map((card, index) => {
                        return <div key={index} className={Styles.dashboardJob}><CustomAccordion card={card} removeCardFromList={removeMethod} /></div>
                    })}
                </Typography>
            </AccordionDetails>
        </Accordion>
        }
    }
    return (
        <>
            <div id={Styles.dashboardJobWrapper}>
                {code && <Alert color="warning" icon={<NotificationImportantIcon />} sx={{ width: "70%", margin: "0 auto", display: 'flex', justifyContent: 'center', textAlign: 'center', }}>
                    If you are not automatically redirected to the upload portal - please navigate back to your submissions and click <b>&apos;Upload Work - Step 2&apos;</b> to continue.</Alert>}
                <h1 id={Styles.heading} style={{ textAlign: "center" }}>Dashboard</h1>
                <hr/>
                {paidCards.length == 0 && pendingPaymentCards.length == 0 && <div id={Styles.createJobWrapper}>
                    <p>No jobs created yet. When you submit to a job or create a job card they will show up here.</p>
                    <Link to="/home?openModal=true">
                        <Button id={Styles.createJobButton}>Create Job</Button>
                    </Link>
                </div>}
                {returnHeadingAccordion(pendingPaymentCardsHeadingAccordion,
                    setPendingPaymentCardsHeadingAccordion,
                    "Jobs Pending Payment",
                    pendingPaymentCards.length,
                    pendingPaymentCards, removePendingPaymentCardFromList)}
                {returnHeadingAccordion(paidCardsHeadingAccordion,
                    setPaidCardsHeadingAccordion,
                    "On-Going Jobs",
                    paidCards.filter(x => !x.isArchived).length,
                    paidCards.filter(x => !x.isArchived), removePaidCardFromList)}
                {submissions.length > 0 && <Accordion expanded={submissionsHeadingAccordion} onChange={() => setSubmissionsHeadingAccordion(!submissionsHeadingAccordion)} sx={{ backgroundColor: "rgba(70, 75, 86, 0.60)", boxShadow: "none" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <h3 style={{ color: "white", margin: "1%" }}>My Submissions <small>({userSubmissions.filter(x => x.paymentState != PaymentStateEnum.Paid).length})</small></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {userSubmissions.filter(x => x.paymentState != PaymentStateEnum.Paid).map((submission, index) => {
                                console.log(submission)
                                return <div key={index} className={Styles.dashboardJob}><SubmissionsCustomAccordion submission={submission} /></div>
                            })}
                        </Typography>
                    </AccordionDetails>
                </Accordion>}
                {returnHeadingAccordion(archivedCardsHeadingAccordion,
                    setArchivedCardsHeadingAccordion,
                    "Archived Jobs",
                    paidCards.filter(x => x.isArchived).length,
                    paidCards.filter(x => x.isArchived), removePaidCardFromList)}

                {archivedSubmissions.length > 0 && <Accordion expanded={archivedSubmissionsHeadingAccordion} onChange={() => setArchivedSubmissionsHeadingAccordion(!archivedSubmissionsHeadingAccordion)} sx={{ backgroundColor: "rgba(70, 75, 86, 0.60)", boxShadow: "none" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <h3 style={{ color: "white", margin: "1%" }}>Archived Submissions <small>({userSubmissions.filter(x => x.paymentState == PaymentStateEnum.Paid).length})</small></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {userSubmissions.filter(x => x.paymentState == PaymentStateEnum.Paid).map((submission, index) => {
                                return <div key={index} className={Styles.dashboardJob}><SubmissionsCustomAccordion submission={submission} /></div>
                            })}
                        </Typography>
                    </AccordionDetails>
                </Accordion>}

            </div>
        </>
    )
}