import CurrentUserContext from "../../client/UserContextProvider"
import { createCard, updateCard } from "../../services/cardService"
import { createAdminBountyOrder, createBountyOrder } from "../../services/paymentsService"
import { CardType } from "../../types/CardType"
import { FilterType } from "../../types/FilterType"
import { PayPalOrderResponseType } from "../../types/PayPalOrderResponseType"
import { Chip, TextField, InputAdornment } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useState, useContext, ChangeEvent } from "react"
import { Alert, Button, ButtonGroup, Col, Form, Modal, Row, Spinner, ThemeProvider, ToggleButton } from "react-bootstrap"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Styles from '../../css/create-job/index.module.css'
import TagSelector from "./TagSelector"
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router-dom"
import textFieldTheme from "../../Utils/themes"
import { bountyMinAmount } from "../../Utils/baseUrl"
import { jobBountyFeeAsPercentage, jobDescriptionMaxChars, jobTitleMaxChars } from "../../Utils/valueSettings"
import { jobBountySuggestionValues } from "../../Utils/valueSettings"
import { UserRoleType } from "../../types/UserRoleType"
interface Props {
    showModal: boolean,
    onClose: () => void;
}
const CreateJobModal: React.FC<Props> = ({ showModal, onClose }) => {
    const [showError, setShowError] = useState(false)
    const [projectTitle, setProjectTitle] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [projectTitleCharacterCount, setProjectTitleCharacterCount] = useState(0)
    const [projectDescriptionCharacterCount, setProjectDescriptionCharacterCount] = useState(0)
    const [specifics, setSpecifics] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [tags, setTags] = useState<FilterType[]>([])
    const [customTags, setCustomTags] = useState<string[]>([])
    const [images, setImages] = useState<string[]>([])
    const [image, setImage] = useState("")
    const [inputError, setInputError] = useState(false)
    const [finishDate, setFinishDate] = useState<string>("")
    const [jobBounty, setJobBounty] = useState("")
    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const [orderSubmitting, setOrderSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const navigate = useNavigate();

    const updateImages = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        console.log(images)
        if (images.length >= 3) {
            return;
        }

        if (e.target.files != null && e.target.files.length > 0) {
            const files = Array.from(e.target.files);

            const promises = files.map((file) => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        const base64String = reader.result;
                        resolve(base64String?.toString() || "");
                    };
                });
            });

            const base64Strings = await Promise.all(promises);
            const updatedImages = [...images, ...base64Strings.filter((s) => !!s)];

            setImages(updatedImages.slice(0, 3));
            setImage("");
        }
    };
    const handleDatePick = (e: Date) => {
        const inputDate = new Date(e);

        const year = inputDate.getFullYear();
        const month = inputDate.getMonth() + 1;
        const day = inputDate.getDate();
        const hours = inputDate.getHours();
        const minutes = inputDate.getMinutes();
        const seconds = inputDate.getSeconds();
        const milliseconds = inputDate.getMilliseconds();

        const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${milliseconds.toString().padEnd(7, '0')}`;
        setFinishDate(formattedDate)
    }

    const onSave = async () => {
        setLoading(true)
        var card: CardType;
        if (projectTitle == "" || projectDescription == "" || jobBounty == "") {
            window.scrollTo(0, 0)
            setErrorMessage("Project title, Description, and Job Bounty must be filled out")
            setInputError(true)
            setTimeout(() => { setInputError(false) }, 5000)
            return
        }
        if (showError) {
            window.scrollTo(0, 0)
            setErrorMessage("Bounty value does not meet minimum requirement")
            setInputError(true)
            setTimeout(() => { setInputError(false) }, 5000)
            return
        }
        setOrderSubmitting(true)
        var convertedTags = tags.map(tag => {
            return Object.keys(tag.Type)[tag.Index]
        })
        convertedTags = convertedTags.concat(customTags)
        convertedTags = convertedTags.filter(x => x != null)
        if (!specifics) {
            card = {
                jobTitle: projectTitle,
                jobDescription: projectDescription,
                tags: [],
                images: [],
                creatorId: currentUser?.id,
                guid: undefined,
                acceptedSubmission: false,
                createdOn: null,
                submissions: [],
                finishDateAsString: finishDate,
                finishDate: null,
                pendingPayment: true,
                tokenId: "",
                jobBounty: undefined,
                isArchived: false,
                paymentReleaseDeadline: null
            }
        } else {
            card = {
                jobTitle: projectTitle,
                jobDescription: projectDescription,
                tags: convertedTags,
                images: images,
                creatorId: currentUser?.id,
                guid: undefined,
                acceptedSubmission: false,
                createdOn: null,
                submissions: [],
                finishDateAsString: finishDate,
                finishDate: null,
                pendingPayment: true,
                tokenId: "",
                jobBounty: undefined,
                isArchived: false,
                paymentReleaseDeadline: null
            }
        }
        try {
            var card: CardType = await createCard(card)
            if (currentUser?.userRole == UserRoleType.Admin) {
                await createAdminBountyOrder(+jobBounty, card.id!, currentUser?.id!)
                card.images = images
                card.pendingPayment = false
                card.jobBounty = +jobBounty
                await updateCard(card)
                window.location.href = `/job/${card.id!}`
                return
            }
            var payPalOrderResponse = await createBountyOrders(card.id!)
            card.tokenId = payPalOrderResponse.id
            card.images = images
            await updateCard(card)
            for (let i = 0; i < payPalOrderResponse.links.length; i++) {
                const link = payPalOrderResponse.links[i];
                if (link.rel == "payer-action") {
                    window.open(link.href, '_blank');
                }
            }
            setOrderSubmitting(false)
        } catch {
            //Add error here
            setOrderSubmitting(false)
        }
        setLoading(false)
        navigate(`/job/${card.id}`)
    }
    const removeTag = (element: string) => {
        var newFilterList = tags.filter((tag) => Object.keys(tag.Type)[tag.Index] !== element)
        setTags(newFilterList)
    }
    const removeCustomTag = (customTag: string) => {
        var newFilterList = customTags.filter(filter => filter !== customTag)
        setCustomTags(newFilterList)
    }
    const removeImage = (image: string) => {
        var updatedImages = images.filter(x => x != image)
        setImages(updatedImages)
    }
    const createBountyOrders = async (cardId: number) => {
        var response: PayPalOrderResponseType = await createBountyOrder(+jobBounty, cardId, currentUser?.id!)
        return response
    }
    const handleToggleChange = (value: number) => {
        setSelectedValue(value);
        setJobBounty(value.toString());
    };
    const checkMin = (e: string) => {
        const value = parseFloat(e);
        if (value < bountyMinAmount) {
            setShowError(true)
        } else {
            setShowError(false)
        }
    }
    const closeModal = () => {
        setSpecifics(false)
        setJobBounty("")
        onClose()
    }
    return (
        <>
            <Modal show={showModal} onHide={closeModal} id={Styles.modalStyle}>
                <Modal.Header closeButton closeVariant="white" style={{ backgroundColor: "#2A2D34" }}>
                    <Modal.Title style={{ color: "white" }}>Create Job</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#2A2D34" }}>
                    <div id={Styles.createJobWrapper}>
                        <div id={Styles.createJobForm}>
                            <Row>
                                <Col>
                                    {inputError && <Alert variant='danger' style={{ textAlign: 'center' }}>{errorMessage}</Alert>}
                                    {/* Title */}
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className={Styles.createJobLabels}>Project Title</Form.Label>
                                        <Form.Control type="text" placeholder="Build me a portfolio website" maxLength={jobTitleMaxChars} onChange={(e) => {
                                            setProjectTitle(e.target.value)
                                            setProjectTitleCharacterCount(e.target.value.length)
                                        }} />
                                        {projectTitleCharacterCount > 0 && <p style={{ float: "right", color: projectTitleCharacterCount == jobTitleMaxChars ? "red" : "white" }}>{projectTitleCharacterCount}/{jobTitleMaxChars}</p>}
                                    </Form.Group>
                                    {/* Description */}
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className={Styles.createJobLabels}>Project Description</Form.Label><br />
                                        <small style={{ color: "#999" }}>This is where you sell your project idea - make it as descriptive and interesting as possible to get the most developers excited to work on it</small>
                                        <Form.Control as="textarea" rows={5} placeholder="What I want my website to include is..." maxLength={jobDescriptionMaxChars} className='my-2' onChange={(e) => {
                                            setProjectDescription(e.target.value)
                                            setProjectDescriptionCharacterCount(e.target.value.length)
                                        }} />
                                        {projectDescriptionCharacterCount > 0 && <p style={{ float: "right", color: projectDescriptionCharacterCount == jobDescriptionMaxChars ? "red" : "white" }}>{projectDescriptionCharacterCount}/{jobDescriptionMaxChars}</p>}
                                    </Form.Group>
                                    <Form.Group className='my-3'>
                                        <p>Do you have preferences about what you want your project built with?</p>
                                        <Form.Check
                                            checked={specifics}
                                            onChange={() => setSpecifics(true)}
                                            type={"checkbox"}
                                            label={`Yes - I want to be specific about what my project should be made with`}
                                            style={{ color: "#999" }}
                                        />
                                        <Form.Check
                                            checked={!specifics}
                                            onChange={() => setSpecifics(false)}
                                            type={"checkbox"}
                                            label={`No - I just want my idea brought to life`}
                                            style={{ color: "#999" }}
                                        />
                                    </Form.Group>
                                    {/* Preferences */}
                                    {specifics && <>
                                        {/* Tags */}
                                        <p style={{ color: "white" }}>Tags: {tags.map((tag, index) =>
                                            <Chip key={index}
                                                onClick={() => removeTag(Object.keys(tag.Type)[tag.Index])}
                                                label={Object.keys(tag.Type)[tag.Index]}
                                                style={{ backgroundColor: Object.values(tag.Type)[tag.Index], cursor: "pointer", margin: "0.5%" }} />)}</p>
                                        <p style={{ color: "white" }}>Custom Tags: {customTags.map((tag, index) =>
                                            <Chip
                                                key={index}
                                                onClick={() => removeCustomTag(tag)}
                                                label={tag}
                                                style={{ backgroundColor: "#6761A8", cursor: "pointer", margin: "0.5%" }} />)}</p>
                                        {(tags.length > 0 || customTags.length > 0) && <p style={{ textAlign: "right", color: tags.length + customTags.length == 7 ? "red" : "white" }}>{tags.length + customTags.length}/7</p>}
                                        <TagSelector setTags={setTags} tags={tags} customTags={customTags} setCustomTags={setCustomTags} />
                                        <div style={{ height: "25px" }} />
                                        {/* DateSelect */}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>


                                                <ThemeProvider theme={textFieldTheme}>
                                                    {/* @ts-ignore */}
                                                    <DatePicker label="Pick Completion Date" onChange={(e) => handleDatePick(e["$d"])} disablePast
                                                        sx={{
                                                            my: 2,
                                                            '& .MuiInputBase-root': {
                                                                color: 'white', // Text color
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'white', // Border color
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'white', // Border color on hover
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'white', // Border color when focused
                                                                },
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: 'white', // Label color
                                                            },


                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </LocalizationProvider>
                                        </div>
                                        <p style={{ textAlign: "center", color: "#999" }}><small>Date you will stop accepting submissions &#40;Optional&#41;</small></p>
                                        {/* Images */}
                                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                            {images.length > 0 &&
                                                images.map((image, index) => (
                                                    <div key={index} style={{ position: "relative", margin: "5px", objectFit: 'cover' }}>
                                                        <img
                                                            src={image}
                                                            style={{ width: "200px", height: "200px" }}
                                                            alt={`Image ${index + 1}`}
                                                        />
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                                width: "fit-content",
                                                                height: "fit-content",
                                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                color: "white",
                                                                borderRadius: "50%",
                                                                padding: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <CancelIcon onClick={() => removeImage(image)} />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        <br />
                                        <input multiple type="file" style={{ display: "none" }} id="file-input" onChange={(e) => {
                                            console.log("Selected files:", e.target.files)
                                            updateImages(e)
                                        }} />
                                        <div id={Styles.imageUploadWrapper}>
                                            <label htmlFor="file-input" style={{ fontSize: "3.5vh", border: "2px solid #999", padding: "2%", cursor: "pointer" }}><FileUploadIcon fontSize='large' />Select</label>
                                            <br />
                                        </div>
                                        <p style={{ color: "#999", textAlign: "center" }}><small >Upload images for your project - Limit of 3</small></p>
                                        <p style={{ textAlign: "center", color: images.length == 3 ? "green" : "white" }}>{images.length}/3 uploaded</p>
                                    </>}
                                    <h6>How much would you like to add to this cards bounty?</h6>
                                    <Row >
                                        <Col>
                                            <ButtonGroup className='my-2'>
                                                {jobBountySuggestionValues.map(value => (
                                                    <ToggleButton
                                                        key={value}
                                                        type="radio"
                                                        checked={selectedValue === value}
                                                        value={value}
                                                        onClick={() => handleToggleChange(value)}
                                                        variant="secondary" id={""}                                                    >
                                                        {`$ ${value}`}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <ThemeProvider theme={textFieldTheme}>
                                                <TextField
                                                    id="outlined-number"
                                                    error={showError}
                                                    helperText={showError ? `Amount must be a minimum of $${bountyMinAmount}` : ""}
                                                    label="Amount"
                                                    variant="outlined"
                                                    value={(jobBounty == "0" ? "" : jobBounty)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        var value = e.target.value
                                                        checkMin(value);
                                                        setSelectedValue(null);
                                                        const regex = /^\d+(\.\d{0,2})?$/;
                                                        if (value === "" || regex.test(value)) {
                                                            setJobBounty(value);
                                                        }
                                                    }}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" style={{ color: 'white' }}>$</InputAdornment>,
                                                        style: { color: 'white' },
                                                        inputProps: {
                                                            style: {
                                                                'appearance': 'textfield',
                                                            },
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: 'white' },
                                                    }}
                                                    sx={{
                                                        my: 2,
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'white',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'white',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'white',
                                                            },
                                                        },
                                                        '& .MuiFormHelperText-root': {
                                                            color: 'white',
                                                        },
                                                        '& input[type=number]::-webkit-outer-spin-button': {
                                                            '-webkit-appearance': 'none',
                                                            margin: 0,
                                                        },
                                                        '& input[type=number]::-webkit-inner-spin-button': {
                                                            '-webkit-appearance': 'none',
                                                            margin: 0,
                                                        },
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                    </Row>
                                    <p>A service fee amount of <span style={{ color: "#6761A8" }}>${Intl.NumberFormat().format((+jobBounty * jobBountyFeeAsPercentage))}</span> will be applied to your payment.</p>
                                    <Button style={{ float: "right" }} onClick={onSave} disabled={orderSubmitting || parseFloat(jobBounty) < bountyMinAmount || jobBounty == ""}>{loading ? <Spinner /> : currentUser?.userRole == UserRoleType.Admin ? <>Create Job As Admin</> : <>Create Job</>}</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateJobModal