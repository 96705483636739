const returnPolicy = <>
    <h1>Build My Idea Returns Policy</h1>

    <p>Thank you for choosing Build My Idea! We understand that sometimes plans change. This policy outlines the return process for our job cards.</p>

    <h2>Refunds for Job Cards:</h2>

    <ul>
        <li>A full refund (minus the non-refundable service fee) will be issued for job cards that are cancelled by you. We will receive a notification of your cancellation and you will receive an email with further steps. In the unlikely event that we are not notified via our systems, please reach out to us directly at <a href="mailto:buildmyidea.contact@gmail.com">buildmyidea.contact@gmail.com</a> and we will handle the refund manually on our end.</li>
    </ul>

    <h2>Reasons for Refunds:</h2>

    <p>We will honor refunds for job cards cancelled for any reason.</p>

    <h2>How to Cancel a Job Card:</h2>

    <p>To cancel a job card, navigate to your dashboard, select the card you would like to cancel and press 'cancel job'. We will then send an email to your with next steps.</p>

    <h2>Refunds Processing Time:</h2>

    <p>Refunds will be initiated within 5-6 business days of your cancellation request and returned to the original payment method used for the purchase.</p>

    <h2>Please Note:</h2>

    <ul>
        <li>This policy is for job card purchases only. Developer services provided after project initiation are not refundable.</li>
        <li>We reserve the right to deny refunds for cancellations deemed fraudulent or abusive.</li>
    </ul>

    <h2>Contact Us:</h2>

    <p>If you have any questions about our return policy, please contact us at <a href="mailto:buildmyidea.contact@gmail.com">buildmyidea.contact@gmail.com</a></p>
</>

export default returnPolicy