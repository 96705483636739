import axios, { AxiosResponse } from "axios";
import { UserType } from "../types/UserType";
import { SetAuthToken } from "./helperService";
import { config } from "../Utils/baseUrl";
import { deleteUser } from "@firebase/auth";
import auth from "./firebaseAuthService";
var baseUrl = `${config.url.API_URL}/users`

export const RetrieveCurrentUserInfo = async (): Promise<UserType> => {
  
  return await axios.get(baseUrl + `/retrieve-current-user-info`, { withCredentials: true }).then(resp => {
    return resp.data}).catch((e) => console.log(e))
}
export const RetrieveUserInfoViaId = async (id: number): Promise<UserType> => {
  
  return await axios.get(baseUrl + `/retrieve-user-info/${id}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}
export const RetrieveUserInfoViaSearchQuery = async (query: string): Promise<UserType[]> => {
  
  return await axios.get(baseUrl + `/retrieve-user-info-via-query?query=${query}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}

export const RetrieveAllUsersInfo = async (): Promise<UserType[]> => {
  
  return await axios.get(baseUrl + `/get-all-users`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}
export const RetrieveUserRange = async (page: number, customersPerPage: number): Promise<UserType[]> => {
  
  return await axios.get(baseUrl + `/get-user-range?page=${page}&amount=${customersPerPage}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}
export const GetUserCount = async (): Promise<number> => {
  
  return await axios.get(baseUrl + `/get-user-count`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}
export const UpdateUserInfo = async (user: UserType) => {
  
  const response = await axios.post(baseUrl + "/update-user-info", user, { withCredentials: true });
  return response
}
const DeleteUserInformationFromServer = async (id: number): Promise<boolean> => {
  return await axios.delete(baseUrl + `/${id}`, { withCredentials: true })
      .then(resp => {
        if (resp.status == 200) 
          return true
        return false;
      }).catch((e) => false)
}

export const DeleteUser = async (id: number, isOAuthAccount: boolean): Promise<boolean> => {
  if (isOAuthAccount) {
    if (await DeleteUserInformationFromServer(id))
      {
        return true
      }
    return false
  }
  var user = auth.currentUser
  if (user) {
    return await deleteUser(auth.currentUser!)
    .then(async () => {
      if (await DeleteUserInformationFromServer(id))
        return true
      return false
    }).catch((error) => {
      console.log(error)
      return false
    });
  }
  return false
}

