import axios from "axios";
import { config } from "../Utils/baseUrl";
import { PayPalOrderResponseType } from "../types/PayPalOrderResponseType";
import { PayPalConfirmOrderResponseType } from "../types/ConfirmOrderResponseType";
import { Payment } from "../types/PaymentType";
const baseUrl = `${config.url.API_URL}/Payments`;

export async function createBountyOrder(amount: number | null, cardId: number, userId: number): Promise<PayPalOrderResponseType> {
    return await axios.post(`${baseUrl}/create-order`, {amount: amount, cardId: cardId, userId: userId}, { withCredentials: true }).then(resp => resp.data)
}
export async function confirmOrder(tokenId: string): Promise<PayPalConfirmOrderResponseType> {
    return await axios.get(`${baseUrl}/confirm-order?orderId=${tokenId}`, { withCredentials: true }).then(resp => resp.data)
}
export async function getUserPayments(userId: number | null): Promise<Payment[]> {
    return await axios.get(`${baseUrl}/get-user-payments?userId=${userId}`, {withCredentials: true }).then(resp => resp.data)
}
export async function deleteCardAndAssociatedPayment(cardId: number): Promise<boolean> {
    return await axios.delete(`${baseUrl}/${cardId}`, {withCredentials: true }).then(resp => resp.data)
}
export async function createAdminBountyOrder(amount: number | null, cardId: number, userId: number): Promise<PayPalOrderResponseType> {
    return await axios.post(`${baseUrl}/create-admin-order`, {amount: amount, cardId: cardId, userId: userId}, { withCredentials: true }).then(resp => resp.data)
}